import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import MEDIA_TYPE from "@/common/enums/mediaTypeEnum";
import LogService from "@/common/services/Log/LogService";

//E2e should be test with UI, not able to send correct ocr document for scanning
@injectable()
class ApplicantOCRFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LogService) private logService: LogService
  ) { }

  post(workspaceUUID: string, applicantId: number, message: any) {
    const formData = new FormData();
    formData.append("dto", new Blob([JSON.stringify(message)], { type: MEDIA_TYPE.APPLICATION_JSON }));
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/ocr`,
      formData,
      {
        headers: { "Content-Type": MEDIA_TYPE.MULTIPART_FORM_DATA },
        onUploadProgress: async (e: any) => {
          this.logService.log("JHA marked this TODO to add progress handling");
        },
      },
      ResponseTypes.Payload
    );
  }
}
export default ApplicantOCRFactory;
