import type HttpHandlerConfig from "@/common/interfaces/httpHandler/HttpHandlerConfig";
import type AxiosRequestInterceptor from "@/common/interfaces/axios/AxiosRequestInterceptor";
import type AxiosResponseInterceptor from "@/common/interfaces/axios/AxiosResponseInterceptor";
import CommonRequestInterceptor from "@/common/interceptors/CommonRequestInterceptor";
import CommonResponseInterceptor from "@/common/interceptors/CommonResponseInterceptor";
import { injectable } from "inversify";

@injectable()
export default class ApplicantChannelHttpHandlerConfig implements HttpHandlerConfig {

  requestInterceptors: AxiosRequestInterceptor[] = [
    new CommonRequestInterceptor(),
  ];

  responseInterceptors: AxiosResponseInterceptor[] = [
    new CommonResponseInterceptor(),
  ];

}